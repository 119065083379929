import React from 'react';
import { Button} from 'reactstrap';
import {baseUrl} from '../shared/baseUrl';
import { Redirect,  Link} from 'react-router-dom';
import queryString from 'query-string';
import UserComponent from './UserComponent';

function finishAccount (code, props) {
    const bearer = 'Bearer ' + localStorage.getItem('token');
    //console.log(code);
    const newAccount = {
        code : code,
    }
    fetch (baseUrl + 'customers/account/setup',{
        method: 'POST',
        body: JSON.stringify(newAccount),
        headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
    })
    .then(res => res.json())
        .then(json => {
            const {
                success,
                // message,
                // account,
            } = json;
            
            //('success', success)
    });
}

const Account = (props) => {
    
    
    if (props.location.search !== ''){
        const query = queryString.parse(props.location.search);
        if(query.error){
            return (
                <>
                <p> An error occured
                </p>
                <p>
                    {query.error}
                </p>
                <p>
                    {query.error_description}
                </p>
                <Link to='myleases'>
                <Button> Back to MyLeases</Button>
                </Link>
                </>
            );
        }
        if(query.scope){
            //console.log('finishing account...')
            finishAccount(query.code, props);
            return(
                <>
                <Redirect to='myleases'/>
                </>
            )
        }
    }
    else {
        return(
            <>
            <UserComponent 
            bookings={props.bookings}
            bookingsLoading={props.bookings.isLoading}
            bookingsErrMess={props.bookings.errMess}
            postListing={props.postListing}
            leases={props.leases}
            leasesLoading={props.leases.isLoading}
            leasesErrMess={props.leases.errMess}
            />
            {/* <Row>
                <Col className="ml-5">
                    {(renterPortal)? (<h4> Your Renter Profile</h4>) : (<h4> Your Rentee Profile</h4>)} 
                    
                    <br></br>
                    <div >

                        <Renter/>
                    </div>
                    <div >
                    <Billing />
                    <br></br>
                    <h4> Your Listings</h4>
                        <div>
                        <Listings leases={props.leases}
                        postListing={props.postListing}
                        />
                        </div>
                    </div>
                    
                    
                </Col>
                <Col>
                    <h4>Bookings</h4>
                    <div className="row">
                        <RenderBookings bookings={props.bookings.bookings}
                        />
                    </div>
                    <br></br>
                </Col>
            </Row> */}
            </>
        )

    }
}

export default Account;