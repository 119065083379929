//prod
// export const baseUrl = 'https://testnet-api.happyleases.com/';
// export const imgUrl = 'https://happyleases-images.s3-us-west-2.amazonaws.com/';

//dev
export const baseUrl = 'https://localhost:3444/';
export const imgUrl = 'https://localhost:3444/';
export const s3Url = 'https://eni-images.s3-us-west-1.amazonaws.com/environment/'; 


export const stripeUrl = 'https://api.stripe.com';
