import React, { useState } from 'react';
import { Card, CardImg, CardImgOverlay, CardText, CardTitle, UncontrolledCarousel } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';
import { imgUrl } from '../shared/baseUrl';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  
  {
    src: '/assets/images/HMS1.2 (2).jpg',
    altText: 'HMS1/2 80/20',
      caption: 'HMS1/2 80/20',
      header: 'HMS1/2 80/20',
      key: '1'
  },
  {
    src: '/assets/images/HMS1_2.jpg',
    altText: 'HMS1/2 80/20',
      caption: 'HMS1/2 80/20',
      header: 'HMS1/2 80/20',
      key: '2'
  },
  {
    src: '/assets/images/Structural steel scrap.jpg',
    altText: 'PNS',
      caption: 'PNS',
      header: 'PNS',
      key: '3'
  },
  {
    src: '/assets/images/Shredded_scrap.jpg',
      altText: 'Shredded Scrap',
      caption: 'Shredded Scrap',
      header: 'Shredded Scrap',
      key: '4'
  }
];

const items2 = [
  {
    src: '/assets/images/Bare bright (2).jpg',
    altText: 'Copper Millberry',
      caption: 'Copper Millberry',
      header: 'Copper Millberry',
      key: '1'
  },
  {
    src: '/assets/images/copper12.jpg',
    altText: 'Copper #1',
      caption: 'Copper #1',
      header: 'Copper #1',
      key: '2'
  },
  {
    src: '/assets/images/copper21.jpg',
    altText: 'Copper #1',
      caption: 'Copper #1',
      header: 'Copper #1',
      key: '3'
  },
  // {
  //   src: '/assets/images/non-ferrous-metals.jpg',
  //   altText: 'Brass (Honey)',
  //     caption: 'Brass (Honey)',
  //     header: 'Brass (Honey)',
  //     key: '4'
  // },{
  //   src: '/assets/images/aluminum_sheets.jpg',
  //   altText: 'Aluminum  6063',
  //     caption: 'Aluminum  6063',
  //     header: 'Aluminum  6063',
  //     key: '5'
  // },
  {
    src: '/assets/images/Alu.Fe engines.jpg',
    altText: 'Aluminum Tense',
      caption: 'Aluminum Tense',
      header: 'Aluminum Tense',
      key: '4'
  },
  {
    src: '/assets/images/Aluminium rims.jpg',
    altText: 'Aluminum Rims',
      caption: 'Aluminum Rims',
      header: 'Aluminum Rims',
      key: '5'
  },
  {
    src: '/assets/images/Electronic engines with copper windings.jpg',
    altText: 'Electronic Engines',
      caption: 'Electronic Engines',
      header: 'Electronic Engines',
      key: '6'
  },
  {
    src: '/assets/images/ubc.jpg',
    altText: 'Used Beverage Cans',
      caption: 'Used Beverage Cans',
      header: 'Used Beverage Cans',
      key: '7'
  }
];

const items3 = [
  {
    src: '/assets/images/cardboard_1.jpg',
    altText: 'OCC #12',
      caption: 'OCC #12',
      header: 'OCC #12',
      key: '1'
  },
  {
    src: '/assets/images/cardboard.jpg',
    altText: 'OCC #12',
      caption: 'OCC #12',
      header: 'OCC #12',
      key: '2'
  }
];

const items4 = [
  {
    src: '/assets/images/Plastic PET.jpg',
    altText: 'PET',
      caption: 'PET',
      header: 'PET',
      key: '1'
  },
  {
    src: '/assets/images/Plastic HDPE (2).jpg',
    altText: 'HDPE',
      caption: 'HDPE',
      header: 'HDPE',
      key: '2'
  },
  {
    src: '/assets/images/plasticScrapSmall.jpg',
    altText: 'PP',
      caption: 'PP',
      header: 'PP',
      key: '3'
  }
];

const items5 = [
  {
    src: '/assets/images/UCO02.jpg',
    altText: 'UCO',
      caption: 'Used Cooking Oils',
      header: 'UCO',
      key: '1'
  }
];

const Product = (props) => {
  

  return (
    <div className="container" >
        <div className="row align-items-start " id="finance" >
            <div className="col-12 col-md m-1" >
            <Card className="carouselImg" >
            <CardTitle tag="h5">Ferrous Steel Scrap</CardTitle>
            <UncontrolledCarousel items={items} />
            </Card>
            </div>
        </div>
        <div className="row align-items-start " id="finance" >
            <div className="col-12 col-md m-1" >
            <Card className="carouselImg">
            <CardTitle tag="h5">Non-Ferrous Scrap (Copper / Brass / Aluminum / Stainless Steel)</CardTitle>
            <UncontrolledCarousel items={items2} />
            </Card>
            </div>
        </div>
        
        <div className="row align-items-start " id="finance" >
            <div className="col-12 col-md m-1" >
            <Card className="carouselImg">
            <CardTitle tag="h5">Cardboard</CardTitle>
            <UncontrolledCarousel items={items3} />
            </Card>
            </div>
        </div>
        <div className="row align-items-start " id="finance" >
            <div className="col-12 col-md m-1" >
            <Card className="carouselImg">
            <CardTitle tag="h5">Plastics</CardTitle>
            <UncontrolledCarousel items={items4} />
            </Card>
            </div>
        </div>
        <div className="row align-items-start " id="finance" >
            <div className="col-12 col-md m-1" >
            <Card className="carouselImg">
            <CardTitle tag="h5">Used Cooking Oils</CardTitle>
            <CardText>
            <p>Used cooking oils pick up service and competitive rates on any location with the below specifications;
            <br/>
            <table>
              {/* <tr>
                <th>Properties</th>
                <th>Value</th>
              </tr> */}
              <tr>
                <th>Delivery state</th>
                <th>liquid (pump able)</th>
              </tr>
              <tr>
                <th>Free fatty acid</th>
                <th>máx. 5% mass</th>
              </tr>
              <tr>
                <th>Moisture and impurities</th>
                <th>máx. 2% mass</th>
              </tr>
              <tr>
                <th>Density 20ºc g/ml</th>
                <th>Mín 0,910 / Máx 0,925</th>
              </tr>
            </table>
            </p>
            </CardText>
            <UncontrolledCarousel items={items5} />
            </Card>
            </div>
        </div>
    </div>
  );
}

export default Product;