import {createStore,  combineReducers, applyMiddleware } from 'redux';
import { createForms } from 'react-redux-form';
import { Dishes } from './dishes';
import { Comments } from './comments';
import { Leases } from './leases';
import { Promotions } from './promotions';
import { Bookings } from './bookings';
import { Leaders } from './leaders';
import { favorites } from './favorites';
import { Auth } from './auth';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { InitialFeedback } from './forms';

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            dishes: Dishes, 
            comments: Comments, 
            promotions: Promotions,
            auth: Auth,
            favorites,
            leaders: Leaders,
            bookings: Bookings,
            leases: Leases,
            ...createForms({
                feedback: InitialFeedback
            })
        }),
        applyMiddleware(thunk,logger)
    );

    return store;
}