import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem, Button , Label, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import { Control, Form, Errors} from 'react-redux-form';
import {connect} from 'react-redux';


const required = (val) => val && val.length; 
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => (val) && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

const mapStateToProps = state => ({
    feedback: state.feedback
});

const imgStyle = {
    maxHeight: 340,
    maxWidth: 340
  }

class Contact extends Component  {
    
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values){
        this.props.postFeedback(values);
        this.props.resetFeedbackForm();
    }


    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem> <Link to='/home'>Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active> Contact Us</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="col-12">
                    <h3>Contact Us</h3>
                    <hr/>
                </div>

                <div className="row ">
                    <div className="col-12 col-sm-4 offset-sm-1 mt-3 mb-3">
                            <h5>Our Address</h5>
                            <address>
                            Happy Leases<br />
                            Metro Detroit, MI<br />
                            USA<br />
                            <i ></i><a href="mailto:happy@happyleases.com">happy@happyleases.com</a>
                            </address>
                    
                    {/* <div className="col-12 col-sm-6 offset-sm-1">
                        <h5>Map of our Location</h5>
                        <img style={imgStyle} src="assets/images/map.png" alt=" map"></img>
                    </div> */}
                    
                        <div className="btn-group" role="group">
                            <a role="button" className="btn btn-primary" href="tel:+12488774154"><i className="fa fa-phone"></i> Call</a>
                            {/* <a role="button" className="btn btn-info" href="www.google.com"><i className="fa fa-skype"></i> Skype</a> */}
                            <a role="button" className="btn btn-success" href="mailto:happy@happyleases.com"><i className="fa fa-envelope-o"></i> Email</a>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-12 offset-sm-1">
                        <h3>Send us your feedback!</h3>
                    </div>
                    <div className="col-12 col-md-9 offset-sm-1">
                        <Form model ="feedback" onSubmit={(values) => this.handleSubmit(values)}>
                            <Row className="form-group">
                                <Label htmlFor="firstname" md={2}>First Name</Label>
                                <Col md={10}>
                                    <Control.text model=".firstname" name="firstname"
                                        placeholder="First Name"
                                        className="form-control"
                                        validators={{
                                            required,minLength: minLength(3), maxLength:maxLength(15)
                                        }}
                                        />
                                    <Errors 
                                        className="text-danger"
                                        model=".firstname"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                            minLength: 'Must be greater than 2 characters',
                                            maxLength: 'Must be 15 characters or less'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="lastname" md={2}>Last Name</Label>
                                <Col md={10}>
                                    <Control.text model=".lastname" name="lastname"
                                        placeholder="Last Name"
                                        className="form-control"
                                        validators={{
                                            required,minLength: minLength(3), maxLength:maxLength(15)
                                        }}
                                         />
                                    <Errors 
                                        className="text-danger"
                                        model=".lastname"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                            minLength: 'Must be greater than 2 characters',
                                            maxLength: 'Must be 15 characters or less'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="telnum" md={2}>Tel. Num</Label>
                                <Col md={10}>
                                    <Control.text model=".telnum" name="telnum"
                                        placeholder="Tel. Num"
                                        className="form-control"
                                        validators={{
                                            required,minLength: minLength(3), maxLength:maxLength(15),isNumber
                                        }}
                                        />
                                    <Errors 
                                        className="text-danger"
                                        model=".telnum"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                            minLength: 'Must be greater than 2 numbers',
                                            maxLength: 'Must be 15 numbers or less',
                                            isNumber: 'Must be a number'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="email" md={2}>Email</Label>
                                <Col md={10}>
                                    <Control.text model=".email" name="email"
                                        placeholder="Email"
                                        className="form-control"
                                        validators={{
                                            required,validEmail
                                        }}
                                        />
                                    <Errors 
                                        className="text-danger"
                                        model=".email"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                            validEmail: 'Invalid Email Address'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col md={{size: 6, offset: 2}}>
                                    <div className="form-check">
                                        <Label check>
                                            <Control.checkbox model=".agree"
                                                name="agree"
                                                className="form-check-input"
                                                /> {' '}
                                            <strong>May we contact you?</strong>
                                        </Label>
                                    </div>
                                </Col>
                                <Col md={{size: 3, offset: 1}}>
                                    <Control.select model=".contactType"
                                        className="form-control">
                                        <option>Tel.</option>
                                        <option>Email</option>
                                    </Control.select>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="message" md={2}>Your Feedback</Label>
                                <Col md={10}>
                                    <Control.textarea model=".message" id="message" name="message"
                                        rows="12"
                                        className="form-control" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col md={{size: 10, offset: 2}}>
                                    <Button type="submit" color="primary">
                                        Send Feedback
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Contact);