import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardImg, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import {imgUrl} from '../shared/baseUrl';
import {Loading} from './LoadingComponent';
import { Fade,  Stagger } from 'react-animation-components';

const imgStyle = {
    maxHeight: 128,
    maxWidth: 128,
    // margin: [ 20, 20 ,20 ,20 ] 
  }

function RenderLeader({leader}){
    return(
        <Fade in>
                <div className = "row">
                <div  className="col-md-2"  >
                    <img  src={imgUrl + leader.image} alt={leader.name} style={imgStyle} />
                    <br></br>
                </div>
                <div className=" col-md-10 ">
                    
                    <h3 > {leader.name}</h3> 
                    <p>{leader.designation}</p>
                    <p>{leader.description}</p>
                    <br/><br/>
                </div>
                </div>
        </Fade>
    );
}

function About(props) {

    const leaders = props.leaders.leaders.map((leader) => {
        return (
            <div key={leader.id} className="col-12 col-mt-3">
                <Stagger in>
                    <RenderLeader leader={leader}></RenderLeader> 
                </Stagger> 
            </div>
        );
    });
    if (props.leaders.isLoading) {
        return(
            <div className="container">
                <div className="row">
                    <Loading />
                </div>
            </div>
        );
    }
    else if (props.leaders.errMess) {
        return(
            <div className="container">
                <div className="row">
                    <h4>{props.leaders.errMess}</h4>
                </div>
            </div>
        );
    }
    else {
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>About Us</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>About Us</h3>
                        <hr />
                    </div>                
                </div>
                <div className="row row-content">
                    <div className="col-12 col-md-6">
                        <h2>Our Story</h2>
                        <p>Booking a water adventure can be quite a hassle, for renters and leasers. Happy Leases 
                             brings a familiar online experience by allowing you to 
                             book and pay all in one place.
                        </p>
                        <p>If you are looking to list your rental items or simply lease, this is the place for you.</p>
                    </div>
                    <div className="col-12 col-md-5">
                        <Card>
                            {/* <CardHeader className="bg-primary text-white">Summary</CardHeader> */}
                            <CardImg top src={imgUrl + 'images/about.jpg'} alt={'group of friends on a boat'} />
                            {/* <CardBody>
                                <dl className="row p-1">
                                    <dt className="col-6">Started</dt>
                                    <dd className="col-6">3 Feb. 2018</dd>
                                    <dt className="col-6">Location</dt>
                                    <dd className="col-6">USA</dd>
                                    <dt className="col-6">Happy Smiles</dt>
                                    <dd className="col-6">Unlimited</dd>
                                </dl>
                            </CardBody> */}
                        </Card>
                    </div>
                    <br></br>
                    <div className="col-12">
                        <Card>
                            <CardBody className="bg-faded">
                                <blockquote className="blockquote">
                                    <p className="mb-0">The easiest rental experience I have had. We were so excited
                                    to book our boat trip within minutes.</p>
                                    <footer className="blockquote-footer">Josh Kim
                                    {/* <cite title="Source Title"> Bahamas, 2019</cite> */}
                                    </footer>
                                </blockquote>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="row row-content">
                    <div className="col-12">
                        <h2>Meet our Partners!</h2>
                        <br/>
                    </div>
                    <div className="col-12">
                        <Media list>
                            {leaders}
                        </Media>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;    