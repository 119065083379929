export const ADD_COMMENT = 'ADD_COMMENT';
export const DISHES_LOADING = 'DISHES_LOADING';
export const DISHES_FAILED = 'DISHES_FAILED';
export const ADD_DISHES = 'ADD_DISHES';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const COMMENTS_FAILED = 'COMMENTS_FAILED';
export const PROMOS_LOADING = 'PROMOS_LOADING';
export const ADD_PROMOS = 'ADD_PROMOS';
export const PROMOS_FAILED = 'PROMOS_FAILED';
export const LEADERS_LOADING = 'LEADERS_LOADING';
export const LEADERS_FAILED = 'LEADERS_FAILED';
export const ADD_LEADERS = 'ADD_LEADERS';
export const ADD_FEEDBACK = 'ADD_FEEDBACK';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const FAVORITES_LOADING = 'FAVORITES_LOADING';
export const FAVORITES_FAILED = 'FAVORITES_FAILED';
export const ADD_FAVORITES = 'ADD_FAVORITES';
export const ADD_LEASES = 'ADD_LEASES';
export const LEASES_LOADING = 'LEASES_LOADING';
export const LEASES_FAILED = 'LEASES_FAILED';
export const ADD_BOOKINGS = 'ADD_BOOKINGS';
export const BOOKINGS_LOADING = 'BOOKINGS_LOADING';
export const BOOKINGS_FAILED = 'BOOKINGS_FAILED';
export const ADD_LISTING = 'ADD_LISTING';

