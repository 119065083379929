import React from 'react';
import {Link} from 'react-router-dom';

function Footer(props){
    return(
        <div className="footer">
        <div className="container">
            <div className="row justify-content-center">             
                {/* <div className="col-4 offset-1 col-sm-2">
                    <h5>Links</h5>
                    <ul className="list-unstyled">
                        <li><Link to="/home"> Home</Link></li>
                        <li><Link to="/aboutus"> About Us</Link></li>
                        <li><Link to="/menu"> Menu</Link></li>
                        <li><Link to="/contactus"> Contact Us</Link></li>
                    </ul>
                </div> */}
                {/* <div className="col-7 col-sm-5">
                    <h5>Our Address</h5>
                    <address>
		              121, Somewhere Warm Blvd.<br />
		              USA<br />
		              {/* <i ></i>+1 800 BOATERS<br /> 
		              <i ></i><a href="mailto:happy@happyleases.com">
                      happy@happyleases.com</a>
                    </address>
                </div> */}
                {/* <div className="col-12 col-sm-4 align-self-center">
                    <div className="text-center">
                        <a className="btn btn-social-icon btn-google" href="http://google.com/+"><i className="fa fa-google-plus"></i></a>
                        <a className="btn btn-social-icon btn-facebook" href="http://www.facebook.com/profile.php?id="><i className="fa fa-facebook"></i></a>
                        <a className="btn btn-social-icon btn-linkedin" href="http://www.linkedin.com/in/"><i className="fa fa-linkedin"></i></a>
                        <a className="btn btn-social-icon btn-twitter" href="http://twitter.com/"><i className="fa fa-twitter"></i></a>
                        <a className="btn btn-social-icon btn-google" href="http://youtube.com/"><i className="fa fa-youtube"></i></a>
                        <a className="btn btn-social-icon" href="mailto:"><i className="fa fa-envelope-o"></i></a>
                    </div>
                </div> */}
            </div>
            <div className="row justify-content-center">             
                <div className="col-auto">
                    <div className="rights">
                    <p>© 2021 ENI - All rights reserved.   </p>
                    </div>
                    <div className="contact">
                    <p> ENI Environment BV <br/>
                    Dokweg 19, Willemstand,<br/>
                    Curacao (Netherlands Antilles). <br/>
                    Tel: +5999-698 0911<br/>
                    Opening hours.<br/>
                    Monday to Sunday 6AM – 6PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Footer; 