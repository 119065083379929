import React,{ useState }  from 'react'; 
import {Card, CardImg, CardTitle,CardText,  CardBody, CardSubtitle,Button,
    CardImgOverlay, UncontrolledCollapse, UncontrolledCarousel} from 'reactstrap';
import { Loading } from './LoadingComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import { imgUrl } from '../shared/baseUrl';
import { FadeTransform } from 'react-animation-components';


const items = [
    {
        src: '/assets/images/curacao_channel.jpg',
        altText: 'Curacao Channel',
        caption: 'Curaçao has always been a centre for trade and ship repair.',
        header: 'Curacao Channel',
        key: '2'
    },
    {
      src: '/assets/images/curacao_cruise.jpg',
      altText: 'Cruiselines',
      caption: 'We help multiple cruiselines properly dispose of their recycables when they stop in the port of Curacao.',
      header: 'Cruiselines',
      key: '2'
    },
    {
      src: '/assets/images/excavator_sky_cropped.png',
      altText: 'Demolition Projects',
      caption: 'We can handle demolition projects of all sizes.',
      header: 'Demolition Projects',
      key: '3'
    }
  ];

function RenderCard({item, isLoading, errMess}){
    if (isLoading) {
        return(
            <Loading />
        );
    }
    else if (errMess) {
        return(
            <h4>{errMess}</h4>
        );
    }
    else 
        return(
            <FadeTransform in
                transformProps={{
                    exitTransform: 'scale(0.5) translateY(-50%)'
                }}>
                <Card>
                    <CardImg src={imgUrl + item.image} alt={item.name}/>
                    <CardBody>
                        <CardTitle>{item.name}</CardTitle>
                        {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null}
                        <CardText>{item.description}</CardText>
                    </CardBody>
                </Card>
            </FadeTransform>

        );
}



// function Home(props){
//     return(
//         <div className="container">
//             <div className="row align-items-start">
//                 <div className="col-12 col-md m-1">
//                     <RenderCard item={props.dish} isLoading ={props.dishesLoading} errMess={props.dishesErrMess} />
//                 </div>
//                 <div className="col-12 col-md m-1">
//                 <RenderCard item={props.promotion} isLoading={props.promoLoading} errMess={props.promoErrMess} />
//                 </div>
//                 <div className="col-12 col-md m-1">
//                     <RenderCard item={props.leader} isLoading={props.leadersLoading} errMess={props.leadersErrMess}/>
//                 </div>

//             </div>
//         </div>
//     );
// }


function Home(){
    return(
        
        <div className="container" >

            <div className="full-width ">
            {/* <CardTitle className="text-center" tag="h1" style={{margin:"5%",color: "grey", borderRadius:"5px", backgroundColor:"transparent",  }}>DELIVERING THE HIGHEST INTERNATIONAL QUALITY STANDARD</CardTitle> */}
            <UncontrolledCarousel style={{width:'100%'}} items={items} />
            <CardImgOverlay style={{textAlign:'center', color:'white', fontSize:'3.5vw', 
        backgroundImage: 'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,0), rgba(0,0,0,.6))'}}>
                DELIVERING THE HIGHEST INTERNATIONAL QUALITY STANDARD
            </CardImgOverlay>
            </div>
            
            <div className="row align-items-start" id="about">
                <div className="col-12 col-md m-1">
                    <Card className="text-center threePanel"  >
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody >
                            <CardTitle tag="h5" style={{marginBottom:"2em"}}>Products</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText >
                            <CardImg src={"assets/images/products.png"} style={{height:"100px", width:"auto"}}/>
                            </CardText>
                            <CardText>
                             {<p>View our currently supported products.</p>}
                            </CardText>
                            <Button color="primary" href='/products'  style={{ marginBottom: '1rem' }}>
                                View

                            </Button>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-12 col-md m-1">
                    <Card className="text-center threePanel" >
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5" style={{marginBottom:"2em"}}>About Us</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText >
                            <CardImg src={"assets/images/recycle.png"} style={{height:"100px", width:"auto"}}/>
                            </CardText>
                            <CardText>
                             {<p>We only deal with the best quality.</p>}
                            </CardText>
                            <Button color="primary" href="home#company" style={{ marginBottom: '1rem' }}>
                                Learn More
                            </Button>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-12 col-md m-1">
                    <Card className="text-center threePanel">
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5" style={{marginBottom:"2em"}}>Projects</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText >
                            <CardImg src={"assets/images/projects_logo.png"} style={{height:"100px", width:"auto"}}/>
                            </CardText>
                            <CardText>
                             {<p>Our projects are green and global. </p>}
                            </CardText>
                            <Button color="primary" href="/projects" style={{ marginBottom: '1rem' }}>
                                View
                            </Button>
                            
                        </CardBody>
                    </Card>
                </div>
            </div>

            <div className="row align-items-start parallax2" id="finance">
                <div className="col-12 col-md m-1">
                    {/* <Card >
                        
                        <CardBody>
                            
                            <CardImg src={"assets/images/bessemer.jpg"}/>
                        </CardBody>
                    </Card> */}
                </div>
            </div>
            <div className="row align-items-start" id="company">
                <div className="col-12 col-md m-1">
                    <Card style={{borderColor:"transparent", marginTop:"3em"}}>
                        <CardTitle tag="h2">About Us</CardTitle>
                    </Card>
                </div>
            </div>
            <div className="row align-items-start" id="company">
                <div className="col-12 col-md m-1">

                <Card style={{borderColor:"transparent"}}>
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5">Our Story</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                           
                           <CardText>
                               {<p>Our yard is minutes away from the deep water terminal.</p>}
                           </CardText>
                            <Button color="primary" id="aboutToggle" style={{ marginBottom: '1rem' }}>
                                More
                            </Button>
                            <UncontrolledCollapse toggler="#aboutToggle">
                            
                            <CardText>
                                {<p>Established in a strategy central location on the Island of Curacao, we have a 8500 M2 facility located in the Curacao docks, minutes a way from the container terminal and with direct access to deep water bulk loading and unloading terminals. 
                                <br/><br/>
                                Our unique location on the Island and regionally, with close proximity to major south American and Caribbean markets provide us an advantages position to receive, process and ship recyclable materials directly to major international consumers. 
                                <br/><br/>
                                With our vast experience and understanding of the trade we strive to receive and recycle all materials including metals both ferrous and non-ferrous, cardboard, waste paper, plastics and Used cooking oils for further biodiesel production. 
                                <br/><br/>
                                We intend to expand our facilities locally and regionally, adding trucks and equipment to existing infrastructure. 
                                <br/><br/>
                                
                                </p>}
                            </CardText>
                            <CardImg src={"assets/images/curacao_yard_4.jpg"} />

                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                    

                </div>
                <div className="col-12 col-md m-1">
                    <Card style={{borderColor:"transparent"}}>
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5">Values</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText>
                                {<p>We believe in a sustainable future.</p>}
                            </CardText>
                            <Button color="primary" id="goalToggle" style={{ marginBottom: '1rem' }}>
                                More
                            </Button>
                            <UncontrolledCollapse toggler="#goalToggle">
                            
                            <CardText>
                                {<p>By using sustainable systems, advanced processing equipment and with our experienced and growing team, we make a positive environmental and economic impact on the communities we service, locally and internationally.  
                                <br/><br/>
                                We offer tailor made solutions to all our vendors big and small, we listen to our vendor’s needs, always upholding our values of honesty, integrity, consistency, and efficiency.  
                                <br/><br/>
                                We regularly train our team on these values as well as the last provisions in international environmental sustainability and compliance. 
                                <br/><br/>
                                </p>}
                            </CardText>
                            <CardImg src={"assets/images/globe.png"}/>
                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row align-items-start" id="company">
                <div className="col-12 col-md m-1">
                    <Card style={{borderColor:"transparent"}}>
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5">Projects</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText>{<p>
                                One of our main sources of material is through demolition projects. 
                                </p>}</CardText>
                            <Button color="primary" id="financeToggle" style={{ marginBottom: '1rem' }}>
                                More
                            </Button>
                            <UncontrolledCollapse toggler="#financeToggle">
                            
                            <CardText>
                                {<p>
                                    
                                    We are equipped and experienced to take on small scale and large projects, including specialized cold cutting projects.
                                    <br/><br/>
                                    We can provide the full circle starting with demolition, removal, processing and shipping of the scrap metals. We always ensure the project is completed on time or before schedule and with safety first, for our own team as well as the project owners. 
                                    

                                </p>}
                            </CardText>
                            <CardImg src={"assets/images/yard_dry.jpg"}/>
                            <CardImg src={"assets/images/excavator_scrap.jpg"}/>
                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                </div>

            
                <div className="col-12 col-md m-1">
                    <Card style={{borderColor:"transparent"}}>
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5">Logistics</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText>{<p>
                                Curaçao has always been a centre for trade and ship repair.  
                                </p>}</CardText>
                            <Button color="primary" id="logisticsToggle" style={{ marginBottom: '1rem' }}>
                                More
                            </Button>
                            <UncontrolledCollapse toggler="#logisticsToggle">
                            <CardText>
                                {<p>
                                    The first cruise ship made its way to Curaçao is 1901. The potential of the harbour in Curaçao however was apparent when the oil was discovered in the Lake of Maracaibo in 1914, and the first dry dock, was built in 1926. The port also became a centre for ship repair and oil refining. Willemstad, the Capital of Curacao, was built around the harbour with an inland water of more than four square miles with a dept of 65 feet making curacao the second largest harbour within the Kingdom of the Netherlands, after Rotterdam. 
                                    <br/><br/>
                                    As our products go to many international destinations, we have long standing relationships with all the major shipping lines and we are constantly improving the efficiency of our own inland and inter-island transportation services.
                                    <br/><br/>
                                    Being a stable country within the Kingdom of the Netherlands, boosting one of the highest standards of living and advanced economies in the Caribbean, Curacao is set to grow further in the region and provide a stable platform for business and commerce to develop.

                                </p>}
                            </CardText>
                            <CardImg src={"assets/images/curacao_map.png"}/>
                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row align-items-start" id="work">
                <div className="col-12 col-md m-1">
                    <Card className="threePanel">
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h4"style={{color:"#37b65e"}}>Work With Us</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                            <CardText>
                                {<p>
                                We are always looking for skilled and motivated individuals to join our team and to contribute to a more sustainable society and clean environment for future generations.
                                </p>}</CardText>
                            <Button color="primary" id="workToggle" style={{ marginBottom: '1rem' }}>
                                More
                            </Button>
                            <UncontrolledCollapse toggler="#workToggle">
                                <CardText>
                                {<p> 
                                    <br/><br/>
                                    We provide extensive training programs and encourage team spirit and a modern problem-solving attitude.
                                    <br/><br/>
                                    Are you looking for a challenge and do you have technical and or operational experience?  
                                    <br/><br/>
                                    Please send your enquiries and/or CV to <a href='mailto:info@eni-environment.com'>info@eni                  -environment.com</a>
                                </p>}
                                </CardText>
                                <div style={{display:'block', width:'80%', justifyContent:'center', marginRight:'10%', marginLeft:'10%'}}>
                                <CardImg src={"assets/images/work_with_us_cropped.png"} style={{height:'250px', width:'auto', margin:'1em'}}></CardImg>
                                <CardImg src={"assets/images/cutting_pipe_cropped.png"} style={{height:'250px', width:'auto', margin:'1em'}}></CardImg>
                                <CardImg src={"assets/images/work_hill_cropped.png"} style={{height:'250px', width:'auto', margin:'1em'}}></CardImg>
                                </div>
                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row align-items-start" id="contact">
                <div className="col-12 col-md m-1">
                    <Card style={{borderColor:"transparent", marginTop:"3em"}}>
                        <CardTitle tag="h2">Contact Us</CardTitle>
                    </Card>
                </div>
            </div>
            <div className="row align-items-start" id="contact">
                <div className="col-12 col-md m-1">

                <Card style={{borderColor:"transparent"}}>
                        {/* <CardImg src={imgUrl + item.image} alt={item.name}/> */}
                        <CardBody>
                            <CardTitle tag="h5">Open 7 Days a Week</CardTitle>
                            {/* {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle>: null} */}
                           
                           <CardText>
                               {<p>We are here to serve you.</p>}
                           </CardText>
                            <Button color="primary" id="contactToggle" style={{ marginBottom: '1rem' }}>
                                Contact
                            </Button>
                            <UncontrolledCollapse toggler="#contactToggle">
                            
                            <CardText>
                                {<p> ENI Environment BV <br/>
                                Dokweg 19, Willemstand,<br/>
                                Curacao (Netherlands Antilles). <br/>
                                Tel: +5999-698 0911<br/>
                                Opening hours.<br/>
                                Monday to Sunday 6AM – 6PM</p>}
                            </CardText>
                            <CardImg src={"assets/images/port_map.png"} />

                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                    

                </div>
            </div>
        </div>
        
    );
}

export default Home;