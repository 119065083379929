import React, { Component, useState } from 'react';
import { Button, Row, Col, Label, Media} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Card, CardImg, CardImgOverlay, CardTitle,
    Breadcrumb, BreadcrumbItem, Modal, ModalBody, ModalHeader,
    InputGroup, InputGroupAddon, Input } from 'reactstrap';
import {baseUrl, imgUrl} from '../shared/baseUrl';
import {client_id,bucketName,region,accessKeyId,secretAccessKey } from '../config';
import { Redirect,  Link} from 'react-router-dom';
import queryString from 'query-string';
import {Loading} from './LoadingComponent';
import { Fade,  Stagger } from 'react-animation-components';
import { leasesLoading } from '../redux/ActionCreators';
import S3FileUpload from 'react-s3';

const required = (val) => val && val.length;
// const checked = (val) => val && true;
// const country = (val) => /^[A-Z]{2}$/i.test(val);
const isNumber = (val) => !isNaN(Number(val));
const maxLength = (len) => (val) => !(val) || (val.length <= len);
// const validPrice = (val) => /^$?([0-9]{1,4}|0)\.\[0-9]{2}$/.test(val);
const validPrice = (val) => /^\$?(([1-9]\d{0,3}(,\d{3})*)|0)?\.\d{1,2}$/i.test(val);
const minLength = (len) => (val) => (val) && (val.length >= len);
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

const config = {
    bucketName: bucketName,
    dirName: 'images', 
    region: region,
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
}

const imgStyle = {
    maxHeight: 128,
    maxWidth: 128
  };


class Renter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSignupOpen: false,
            isBillingOpen: false,
            setupBegan: false,
            isLoading: true,
            error: null,
            account: null,
            currently_due: null,
            waiver: null,
        };
        this.toggleSignup = this.toggleSignup.bind(this);
        this.toggleBilling = this.toggleBilling.bind(this);
        this.onChange = this.onChange.bind(this);
       
    }

    toggleSignup(){
        this.setState({
            isSignupOpen: !this.state.isSignupOpen
        });
    }

    toggleBilling(){
        this.setState({
            isBillingOpen: !this.state.isBillingOpen
        });
    }

    onChange(value){
        this.setState({
            waiver: value
        });
    }

  
    render(){
        return (
            <>
            <div className=" col-md-9">
                <LocalForm>
                    <Row className="form-group">
                        <Label htmlFor="firstname" md={2}>First Name</Label>
                        <Col md={10}>
                            <Control.text model=".firstname" name="firstname"
                                placeholder="First Name"
                                className="form-control"
                                validators={{
                                    required,minLength: minLength(3), maxLength:maxLength(15)
                                }}
                                />
                            <Errors 
                                className="text-danger"
                                model=".firstname"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                    minLength: 'Must be greater than 2 characters',
                                    maxLength: 'Must be 15 characters or less'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="lastname" md={2}>Last Name</Label>
                        <Col md={10}>
                            <Control.text model=".lastname" name="lastname"
                                placeholder="Last Name"
                                className="form-control"
                                validators={{
                                    required,minLength: minLength(3), maxLength:maxLength(15)
                                }}
                                />
                            <Errors 
                                className="text-danger"
                                model=".lastname"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                    minLength: 'Must be greater than 2 characters',
                                    maxLength: 'Must be 15 characters or less'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="telnum" md={2}>Tel. Num</Label>
                        <Col md={10}>
                            <Control.text model=".telnum" name="telnum"
                                placeholder="Tel. Num"
                                className="form-control"
                                validators={{
                                    required,minLength: minLength(3), maxLength:maxLength(15),isNumber
                                }}
                                />
                            <Errors 
                                className="text-danger"
                                model=".telnum"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                    minLength: 'Must be greater than 2 numbers',
                                    maxLength: 'Must be 15 numbers or less',
                                    isNumber: 'Must be a number'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Label htmlFor="email" md={2}>Email</Label>
                        <Col md={10}>
                            <Control.text model=".email" name="email"
                                placeholder="Email"
                                className="form-control"
                                validators={{
                                    required,validEmail
                                }}
                                />
                            <Errors 
                                className="text-danger"
                                model=".email"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                    validEmail: 'Invalid Email Address'
                                }}
                            />
                        </Col>
                    </Row>
                    <Col md={{offset: 2}}>
                    <input
                        type='file'
                        accept='.pdf'
                        onChange={(value)=>this.onChange(value)}
                    />
                    </Col>
                    <br>
                    </br>
                    <Button>
                        Update Account Information
                    </Button>
                </LocalForm>
                </div>
            </>
        );
    }
}

class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: null,
            isRenter: null,
        };
        this.fetchAccount = this.fetchAccount.bind(this);
    }

    
    componentDidMount(){
        this.fetchAccount();
        console.log("Leases did mount")
    }
    


    componentWillMount(){
        console.log("Billing will mount")
    }

    componentWillUpdate(){
        console.log("Billing will change")
    }

    componentDidUpdate (){
        console.log("Billing did update")
    }

    fetchAccount() {
       
        const bearer = 'Bearer ' + localStorage.getItem('token');

        fetch(baseUrl + 'customers/account', {
            headers: {
                'Authorization': bearer
            },
        })
        .then( res => res.json())
            .then(json => {
                const {
                    success,
                    message,
                    setupBegan,
                    account,
                    isRenter,
                } = json;
                console.log(account)
                if (success) {
                    this.setState({
                        setupBegan,
                        isLoadingFieldsNeeded: false, 
                        account,
                        isRenter,
                    });
                }
                else {
                    this.setState({
                        error:message,
                        isLoadingFieldsNeeded: false, 
                    });
                }
            })
    }
    
    render(){
         
        console.log('account', this.state.account)
        // if (renterPortal)
        // {
            if (!this.state.account) {
            
                return (
                    <>
                        <h4>Billing Details</h4>
                        <p>Billing account not set up yet</p>
                        <Button >
                            <a href= {`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write` }> Register Your Account </a>
                        </Button>
                    </>
                ); 
    
            }
            else { 
                return(
                    <>
                    <h4>Billing Details</h4>
                    <Button > 
                        <a href="https://dashboard.stripe.com/login">
                        Edit Account</a>
                    </Button>
                    </>
                )
            }
        // }
        // else {
        //     return(
        //         <></>
        //     )
        // }

        
    }
 
}

function RenderBookings({bookings}) {
    console.log('bookings: ', bookings)
    if (bookings.length > 0)
        // if (renterPortal){
            return(
                <div className=" col-md-1 m-1">
                            {bookings.map((bookings) => {
                                var showDate = new Date(bookings.date)
                                return (
                                    <div key={bookings._id} className=" col-mt-5">
                                        <Stagger in>
                                        <Fade in>
                                            <Media tag="li">
                                                <Media left middle>
                                                    <Media object src={imgUrl + bookings.bookingId.image} alt={bookings.bookingId.name} style={imgStyle}/>
                                                </Media>
                                                <Media body className="ml-5">
                                                    <Media heading> {bookings.rentee.username}</Media> 
                                                    <p>{bookings.bookingId.name}</p>
                                                    <p>{showDate.toDateString()}</p>
                                                    {(bookings.slot1) ?  (
                                                            <p>{bookings.bookingId.slot1_start}:00 AM to {bookings.bookingId.slot1_end-12}:00 PM </p>
                                                    ) : (<></>)}
                                                    {(bookings.slot2) ?  (
                                                            <p>{bookings.bookingId.slot2_start-12}:00 PM to {bookings.bookingId.slot2_end-12}:00 PM </p>
                                                    ) : (<></>)}
                                                    <p>{bookings.rentee.email}</p>
                                                    <br/><br/>
                                                </Media>
                                            </Media>
                                        </Fade>
                                        </Stagger> 
                                    </div>
                                );
                            })}
                </div>
            );
        // }
        // else {
        //     return(
        //         <div className="col-12  m-1">
        //                     {bookings.map((bookings) => {
        //                         var showDate = new Date(bookings.date)
        //                         return (
        //                             <div key={bookings._id} className="col-12 col-mt-5">
        //                                 <Stagger in>
        //                                 <Fade in>
        //                                     <Media tag="li">
        //                                         <Media left middle>
        //                                             <Media object src={baseUrl + bookings.bookingId.image} alt={bookings.bookingId.name} style={imgStyle}/>
        //                                         </Media>
        //                                         <Media body className="ml-5">
        //                                             <Media heading> {bookings.owner.username}</Media> 
        //                                             <p>{bookings.bookingId.name}</p>
        //                                             <p>{showDate.toDateString()}</p>
        //                                             {(bookings.slot1) ?  (
        //                                                     <p>{bookings.bookingId.slot1_start}:00 AM to {bookings.bookingId.slot1_end-12}:00 PM </p>
        //                                             ) : (<></>)}
        //                                             {(bookings.slot2) ?  ( 
        //                                                     <p>{bookings.bookingId.slot2_start-12}:00 PM to {bookings.bookingId.slot2_end-12}:00 PM </p>
        //                                             ) : (<></>)}
        //                                             <p>{bookings.owner.email}</p>
        //                                             <br/><br/>
        //                                         </Media>
        //                                     </Media>
        //                                 </Fade>
        //                                 </Stagger> 
        //                             </div>
        //                         );
        //                     })}
        //         </div>
        //     );
        // }      
    
    else
        return(
            <div>No bookings yet</div>
        );
}

function deleteListing(dishId){
    console.log('deletion clicked');
    const bearer = 'Bearer ' + localStorage.getItem('token');

    fetch(baseUrl + 'dishes/' + dishId, {
        method: 'DELETE',
        headers: {
            'Authorization': bearer
        },
    })
    .then( res => res.json())
        .then(json => {
            console.log(json)
            if (json.ok === 1) {
                console.log('successfully deleted')
            }
            else {
                console.log('deletion not successful')
            }
        })
}

function RenderLease({ dish }) {
    return(
        <>
        <Card>
            <Link to={`/menu/${dish._id}`} >
                <CardImg width="100%" src={imgUrl + dish.image} alt={dish.name} />
                <CardImgOverlay>
                    <CardTitle style={{ backgroundColor: '#FFF',
                     color: '#85e7f2', borderRadius: '4px', padding: '2%',
                      width: '50%'}}>{dish.name}</CardTitle>
                </CardImgOverlay>
            </Link>
        </Card>
        <Row > 
            <Col >
            <Button onClick={deleteListing.bind(this,dish._id)}>Delete</Button>
            </Col>
        </Row>
        </>
    );
}

const Listings = (props) => {
    const menu = props.leases.leases.map((dish) => {
        return (
            <div className = "row">
            <div key={dish._id} className=" col-md-8">
                <RenderLease dish={dish} 
                //  postListing={props.postListing} 
                 />
            </div>
            </div>
        );
    });

    if (props.leases.isLoading) {
        return(
            <div className="container">
                <div className="row">
                    <Loading />
                </div>
            </div>
        );
    }
    else if (props.leases.errMess) {
        return(
            <div className="container">
                <div className="row">
                    <h4>{props.leases.errMess}</h4>
                </div>
            </div>
        );
    }
    else
        return (
            <div className="container">
                <div className="row">
                    {menu}
                    <ListingForm dishId={props.leases} postListing={props.postListing} />
                </div>
            </div>
        );
}

class ListingForm extends Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
          isNavOpen: false,
          isModalOpen: false,
          image: null,
          rate: 'Daily'
        };
    }

    toggleModal() {
        this.setState({
          isModalOpen: !this.state.isModalOpen
        });
    }
    componentDidMount(){
        console.log("Leases did mount")
    }

    componentWillMount(){
        console.log("Leases will mount")
    }

    componentWillUpdate(){
        console.log("Leases will change")
    }

    componentDidUpdate (){
        console.log("Leases did update")
    }

    
    handleChange(values){
        
    
        this.setState({
            rate: values.rate,
            image: values.image,
        });
    }


    handleSubmit(values) {
        S3FileUpload
        .uploadFile(values.image[0], config)
        .then(data => console.log('data: ', data))
        .catch(err => console.error(err))

        this.toggleModal();
        console.log('values: ',values)
        this.props.postListing(values);
    }

    render() {
        return(
        <Row>
            
            <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>New Listing</ModalHeader>
            <ModalBody>
                <LocalForm 
                onChange={(values) => this.handleChange(values)}
                onSubmit={(values) => this.handleSubmit(values)}
                >
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="name">Name</Label>
                        <Control.text model=".name" id="name" className="form-control"
                        validators={{
                            required,
                          }}
                        />
                        <Errors 
                                className="text-danger"
                                model=".name"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="image">Image</Label>
                        <br></br>
                        <Control.file
                            accept='.png, .jpg, .jpeg, .svg'
                            model=".image"
                            onChange={(values)=>this.handleChange(values)}
                            validators={{
                                required,
                              }}
                            />
                            <Errors 
                                className="text-danger"
                                model=".image"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="description">Description</Label>
                        <Control.textarea model=".description" id="description" className="form-control"
                        rows="5"
                        validators={{
                            required,
                          }}
                        />
                        <Errors 
                                className="text-danger"
                                model=".description"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="persons">Number of Persons</Label>
                        <Control.select model=".persons" id="persons" className="form-control"
                        defaultValue="4"
                        validators={{
                            required,
                          }}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                        </Control.select>
                        <Errors 
                            className="text-danger"
                            model=".persons"
                            show="touched"
                            messages={{
                                required: 'Required',
                            }}
                        />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="category">Category</Label>
                        <Control.select model=".category" id="category" className="form-control"
                        defaultValue="Boats"
                        validators={{
                            required,
                          }}
                        >
                            <option>Boats</option>
                            <option>Kayaks &amp; Canoes</option>
                            <option>Jetskis</option>
                        </Control.select>
                        <Errors 
                                className="text-danger"
                                model=".category"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="price">Price</Label>
                        <Control.text model=".price" id="price" className="form-control"
                        placeholder="450.00" type="number"
                        validators={{
                            required,
                            validPrice,
                          }}
                        />
                        <Errors 
                            className="text-danger"
                            model=".price"
                            show="touched"
                            messages={{
                                required: 'Required ',
                                validPrice: 'Must be less than 9999.99 and include cent values '
                            }}
                        />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                        <Label htmlFor="rate">Rate</Label>
                        <Control.select model=".rate" id="rate" className="form-control"
                        defaultValue="Daily" onChange={this.rateChange}
                        validators={{
                            required,
                          }}
                        >
                            <option>Hourly</option>
                            <option>Daily</option>
                        </Control.select>
                        <Errors 
                            className="text-danger"
                            model=".rate"
                            show="touched"
                            messages={{
                                required: 'Required',
                            }}
                        />
                        </Col>
                    </Row>
                    { (this.state.rate === "Daily") ?
                        (<>
                            <Row className="form-group">
                        <Col>
                        <Label htmlFor="halfprice">Half Day Price</Label>
                        
                        <Control.text model=".halfprice" id="halfprice" className="form-control"
                        placeholder="275.00" defaultValue="275.00" type="number"
                        validators={{
                            required,
                            validPrice,
                          }}
                        />
                        <Errors 
                            className="text-danger"
                            model=".halfprice"
                            show="touched"
                            messages={{
                                required: 'Required ',
                                validPrice: 'Must be less than 9999.99 and include cent values '
                            }}
                        />
                        </Col>
                    </Row>
                            <Row className="form-group">
                                <Col>
                                <Label htmlFor="times">Half Day Time Slots</Label>
                                    <Col>
                                    <Label htmlFor="times">Slot 1: Start</Label>
                                    <Control.select model=".slot1_start" id="slot1_start" className="form-control"
                                    defaultValue="11"
                                    validators={{
                                        required,
                                    }}
                                    >
                                        <option value="7">7:00 AM</option>
                                        <option value="8">8:00 AM</option>
                                        <option value="9">9:00 AM</option>
                                        <option value="10">10:00 AM</option>
                                        <option value="11">11:00 AM</option>
                                        <option value="12">12:00 PM</option>
                                        <option value="13">1:00 PM</option>
                                        <option value="14">2:00 PM</option>
                                        <option value="15">3:00 PM</option>
                                        <option value="16">4:00 PM</option>
                                        <option value="17">5:00 PM</option>
                                        <option value="18">6:00 PM</option>
                                        <option value="19">7:00 PM</option>
                                    </Control.select>
                                    <Errors 
                                        className="text-danger"
                                        model=".slot1_start"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                        }}
                                    />
                                    </Col>
                                    <Col>
                                    <Label htmlFor="times">Slot 1: End</Label>
                                    <Control.select model=".slot1_end" id="slot1_end" className="form-control"
                                    defaultValue="15"
                                    validators={{
                                        required,
                                    }}
                                    >
                                        <option value="7">7:00 AM</option>
                                        <option value="8">8:00 AM</option>
                                        <option value="9">9:00 AM</option>
                                        <option value="10">10:00 AM</option>
                                        <option value="11">11:00 AM</option>
                                        <option value="12">12:00 PM</option>
                                        <option value="13">1:00 PM</option>
                                        <option value="14">2:00 PM</option>
                                        <option value="15">3:00 PM</option>
                                        <option value="16">4:00 PM</option>
                                        <option value="17">5:00 PM</option>
                                        <option value="18">6:00 PM</option>
                                        <option value="19">7:00 PM</option>
                                    </Control.select>
                                    <Errors 
                                        className="text-danger"
                                        model=".slot1_end"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                        }}
                                    />
                                    </Col>
                                    <Col>
                                    <Label htmlFor="times">Slot 2: Start</Label>
                                    <Control.select model=".slot2_start" id="slot2_start" className="form-control"
                                    defaultValue="15"
                                    validators={{
                                        required,
                                    }}
                                    >
                                        <option value="7">7:00 AM</option>
                                        <option value="8">8:00 AM</option>
                                        <option value="9">9:00 AM</option>
                                        <option value="10">10:00 AM</option>
                                        <option value="11">11:00 AM</option>
                                        <option value="12">12:00 PM</option>
                                        <option value="13">1:00 PM</option>
                                        <option value="14">2:00 PM</option>
                                        <option value="15">3:00 PM</option>
                                        <option value="16">4:00 PM</option>
                                        <option value="17">5:00 PM</option>
                                        <option value="18">6:00 PM</option>
                                        <option value="19">7:00 PM</option>
                                    </Control.select>
                                    <Errors 
                                        className="text-danger"
                                        model=".slot2_start"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                        }}
                                    />
                                    </Col>
                                    <Col>
                                    <Label htmlFor="times">Slot 2: End</Label>
                                    <Control.select model=".slot2_end" id="slot2_end" className="form-control"
                                    defaultValue="19"
                                    validators={{
                                        required,
                                    }}
                                    >
                                        <option value="7">7:00 AM</option>
                                        <option value="8">8:00 AM</option>
                                        <option value="9">9:00 AM</option>
                                        <option value="10">10:00 AM</option>
                                        <option value="11">11:00 AM</option>
                                        <option value="12">12:00 PM</option>
                                        <option value="13">1:00 PM</option>
                                        <option value="14">2:00 PM</option>
                                        <option value="15">3:00 PM</option>
                                        <option value="16">4:00 PM</option>
                                        <option value="17">5:00 PM</option>
                                        <option value="18">6:00 PM</option>
                                        <option value="19">7:00 PM</option>
                                    </Control.select>
                                    <Errors 
                                        className="text-danger"
                                        model=".slot2_end"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                        }}
                                    />
                                    </Col>
                                </Col>
                            </Row>
                        </>)
                            :
                        (<></>)
                    }
                    <Button type="submit" className="bg-primary">
                        Submit
                    </Button>
                </LocalForm>
            </ModalBody>
           </Modal>
           
            <Button outline onClick={this.toggleModal}><span className="fa fa-plus fa-md"></span> Add New Listing</Button>
           
        </Row>
        );
    }
}

class UserComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountSetup: true,
            renter: true,
        };
        this.fetchAccount = this.fetchAccount.bind(this);
    }

    componentWillMount(){
        this.fetchAccount()
    }

    async fetchAccount(){
        const bearer = 'Bearer ' + localStorage.getItem('token');

        await fetch(baseUrl + 'users/myuser', {
            headers: {
                'Authorization': bearer
            },
        })
        .then( res => res.json())
            .then(json => {
                console.log(json[0].renter, json[0].acct_id)
                if(!json[0].acct_id){
                    this.setState({
                        accountSetup : false, 
                    })
                }
                if(!json[0].renter){
                    this.setState({
                        renterPortal : false,
                    })
                }
            })
    }

    render(){
        console.log('renter: ', this.state.renter, 'accountSetup:', this.state.accountSetup)
        return(
            <Row>
                <div className="col-md-6 ml-5">
                    <br></br>
                    <div >
                        {/* <Renter/> */}
                    </div>
                        {/* {(this.state.renter)?( */}
                        <div >
                        <Billing />
                        <br></br>
                        <h4> Your Listings</h4>
                            <div>
                                {/* called function  */}
                            <Listings leases={this.props.leases}
                            postListing={this.props.postListing}
                            />
                            </div>
                        </div>
                        {/* ):(<></>) }*/}
                    
                </div>
                <div className="col-md-4 ml-5" >
                    
                    <h4>Bookings</h4>
                    
                        {/* called function  */}
                        <RenderBookings bookings={this.props.bookings.bookings}
                        />
                    
                    <br></br>
                </div>
            </Row>

        )
    }
}

export default UserComponent;