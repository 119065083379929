import React, {Component} from 'react';
import Main from './components/MainComponent';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ConfigureStore} from './redux/configureStore';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-198987387-2');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.ga('send', 'pageview', '/home');

const store = ConfigureStore();

class App extends Component {

  
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Main />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
