import React, { Component } from 'react';
import Home from './HomeComponent';
import Account from './LeasesComponent';
import Product from './ProductComponent';
import Project from './ProjectComponent'; 
import DishDetail from './DishdetailComponent';
import Favorites from './FavoriteComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Contact from './ContactComponent';
import About from './AboutComponent'
import { connect } from 'react-redux';
import { fetchBookings, postListing,postComment, fetchLeases, fetchDishes, fetchComments, fetchPromos, fetchLeaders,loginUser, logoutUser, postFeedback,signUp, fetchFavorites, postFavorite, deleteFavorite } from '../redux/ActionCreators';
import { actions } from 'react-redux-form';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const mapStateToProps = state => {
  return {
    dishes: state.dishes,
    leases: state.leases,
    comments: state.comments,
    bookings: state.bookings,
    promotions: state.promotions, 
    leaders: state.leaders,
    favorites: state.favorites,
    auth: state.auth
  }    
}

const mapDispatchToProps = dispatch => ({
  postComment: (dishId, rating, author, comment) => dispatch(postComment(dishId, rating, author, comment)),
  postListing: (values) => dispatch(postListing(values)),
  postFeedback: (feedback)  => dispatch(postFeedback(feedback)),
  fetchDishes: () => {dispatch(fetchDishes())},
  resetFeedbackForm: () => {dispatch(actions.reset('feedback'))},
  fetchComments: () => {dispatch(fetchComments())},
  fetchPromos: () => {dispatch(fetchPromos())}, 
  fetchLeaders: () => {dispatch(fetchLeaders())},
  signUp: (values) => dispatch(signUp(values)),
  loginUser: (creds) => dispatch(loginUser(creds)),
  logoutUser: () => dispatch(logoutUser()),
  fetchFavorites: () => dispatch(fetchFavorites()),
  postFavorite: (dishId) => dispatch(postFavorite(dishId)),
  deleteFavorite: (dishId) => dispatch(deleteFavorite(dishId)), 
  fetchLeases: () => {dispatch(fetchLeases())},
  fetchBookings: () => {dispatch(fetchBookings())},
});

class Main extends Component {

  componentDidMount(){
    this.props.fetchDishes();
    this.props.fetchComments();
    this.props.fetchPromos();
    this.props.fetchLeaders();
    this.props.fetchLeases();
    this.props.fetchBookings();
  }

  render() {
    // console.log('props: ', this.props)
    const HomePage = () => {
      return (
        <Home />
        // <Home dish={this.props.dishes.dishes.filter((dish) => dish.featured)[0]}
        //   dishesLoading={this.props.dishes.isLoading}
        //   dishesErrMess={this.props.dishes.errMess}
        //   promotion={this.props.promotions.promotions.filter((promo) => promo.featured)[0]}
        //   promoLoading={this.props.promotions.isLoading}
        //   promoErrMess={this.props.promotions.errMess}
        //   leader={this.props.leaders.leaders.filter((leader) => leader.featured)[0]}
        //   leadersLoading={this.props.leaders.isLoading}
        //   leadersErrMess={this.props.leaders.errMess}
        // />
      )
    }

    

    const DishWithId = ({match}) => {
      return(
        this.props.auth.isAuthenticated
        ?
        <DishDetail dish={this.props.dishes.dishes.filter((dish) => dish._id === match.params.dishId)[0]}
          isLoading={this.props.dishes.isLoading}
          errMess={this.props.dishes.errMess}
          comments={this.props.comments.comments.filter((comment) => comment.dish === match.params.dishId)}
          commentsErrMess={this.props.comments.errMess}
          postComment={this.props.postComment}
          // favorite={this.props.favorites.favorites.dishes.some((dish) => dish._id === match.params.dishId)}
          postFavorite={this.props.postFavorite}
          />
        :
        <DishDetail dish={this.props.dishes.dishes.filter((dish) => dish._id === match.params.dishId)[0]}
          isLoading={this.props.dishes.isLoading}
          errMess={this.props.dishes.errMess}
          comments={this.props.comments.comments.filter((comment) => comment.dish === match.params.dishId)}
          commentsErrMess={this.props.comments.errMess}
          postComment={this.props.postComment}
          favorite={false}
          postFavorite={this.props.postFavorite}
          />
      );
    }
    
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        this.props.auth.isAuthenticated
          ? <Component {...props} />
          : <Redirect to={{
              pathname: '/home',
              state: { from: props.location }
            }} />
      )} />
    );


    return (
      <div>
        <Header signUp={this.props.signUp}
        auth={this.props.auth} 
        loginUser={this.props.loginUser} 
        logoutUser={this.props.logoutUser} 
        /> 
          <TransitionGroup>
            <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
              <Switch>
                <Route path="/home" component={HomePage} />
                <Route exact path='/aboutus' component={() => <About leaders={this.props.leaders} />} />
                <Route exact path="/products" component={() => <Product dishes={this.props.dishes} />} />
                <Route exact path="/projects" component={() => <Project dishes={this.props.dishes} />} />
                <Route path="/menu/:dishId"
                // render= {(props) => <PurchaseForm {...props}/>}
                 component={DishWithId} />
                <Route path="/myleases" 
                component={() => <Account location={this.props.location}
                bookings={this.props.bookings}
                bookingsLoading={this.props.bookings.isLoading}
                bookingsErrMess={this.props.bookings.errMess}
                postListing={this.props.postListing}
                leases={this.props.leases}
                leasesLoading={this.props.leases.isLoading}
                leasesErrMess={this.props.leases.errMess}
                />} />
                <PrivateRoute exact path="/favorites" component={() => <Favorites favorites={this.props.favorites} deleteFavorite={this.props.deleteFavorite} />} />
                <Route exact path="/contactus" component={() => <Contact resetFeedbackForm={this.props.resetFeedbackForm} postFeedback={this.props.postFeedback} />} />
                <Redirect to="/home" />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        <Footer />

      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));