import React, { Component } from 'react';
import { Card, CardImg, CardImgOverlay, CardText, CardBody,
    CardTitle, Breadcrumb, BreadcrumbItem, Label, 
    Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Loading } from './LoadingComponent';
import { baseUrl, imgUrl } from '../shared/baseUrl';
import {stripe_pk} from '../config';
import { FadeTransform, Fade, Stagger } from 'react-animation-components';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SplitForm from "./SplitForm";
import Calendar from 'react-calendar';

const checked = (val) => val && true;

    function RenderDish({dish, favorite, postFavorite}) {
        var maxPeople = dish.max_persons; 
        return(
                <>
                    <FadeTransform in 
                        transformProps={{
                            exitTransform: 'scale(0.5) translateY(-50%)'
                        }}>
                        <Card>
                            <CardImg top src={imgUrl + dish.image} alt={dish.name} />
                            {/* <CardImgOverlay>
                                <Button outline color="primary" onClick={() => favorite ? console.log('Already favorite') : postFavorite(dish._id)}>
                                    {favorite ?
                                        <span className="fa fa-heart"></span>
                                        : 
                                        <span className="fa fa-heart-o"></span>
                                    }
                                </Button>
                            </CardImgOverlay> */}
                            <CardBody>
                                <CardTitle><b>About the Rental</b></CardTitle>
                                <CardText>{dish.description}</CardText>
                                <CardText>Fits up to {({maxPeople} ===1 )?(<>{maxPeople} person</>):(<>{maxPeople} people</>)}</CardText>
                                <CardTitle><b>About the Owner</b></CardTitle>
                                <CardText>{dish.owner.description}</CardText>
                                <CardTitle><b>Cancellation Policy</b></CardTitle>
                                <CardText>{dish.owner.cancellation}</CardText>
                                <CardTitle><b>Weather Policy</b></CardTitle>
                                <CardText>{dish.owner.weather}</CardText>
                                <CardText> Please contact {dish.owner.username} at <a href={'mailto:'+dish.owner.email}>{dish.owner.email}</a> for more information.</CardText>
                            </CardBody>
                        </Card>
                    </FadeTransform>
                </>
            );

    }

    function RenderComments({comments, postComment, dishId}) {
        if (comments != null)
            return(
               <>
                <h4>Reviews</h4>
                    <ul className="list-unstyled">
                        <Stagger in>
                            {comments.map((comment) => {
                                return (
                                    <Fade in key={comment._id}>
                                        <li>
                                        <p>{comment.comment}</p>
                                        <p>{comment.rating} stars</p>
                                        <p>-- {comment.author.firstname} {comment.author.lastname} , {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day:'2-digit'}).format(new Date(Date.parse(comment.updatedAt)))}</p>
                                        </li>
                                    </Fade>
                                );
                            })}
                        </Stagger>
                    </ul>
                <CommentForm dishId={dishId} postComment={postComment} />
                </>
            );
        else
            return(
                <div></div>
            );
    }

    class CommentForm extends Component {

        constructor(props) {
            super(props);
    
            this.toggleModal = this.toggleModal.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            
            this.state = {
              isNavOpen: false,
              isModalOpen: false
            };
        }
    
        toggleModal() {
            this.setState({
              isModalOpen: !this.state.isModalOpen
            });
        }

       
    
        handleSubmit(values) {
            this.toggleModal();
            this.props.postComment(this.props.dishId, values.rating, values.comment);
        }
    
        render() {
            return(
            <div>
                <Button outline onClick={this.toggleModal}><span className="fa fa-pencil fa-lg"></span> Submit Comment</Button>
                <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>Submit Comment</ModalHeader>
                <ModalBody>
                    <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                        <Row className="form-group">
                            <Col>
                            <Label htmlFor="rating">Rating</Label>
                            <Control.select model=".rating" id="rating" className="form-control">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Control.select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                            <Label htmlFor="comment">Comment</Label>
                            <Control.textarea model=".comment" id="comment"
                                        rows="6" className="form-control" />
                            </Col>
                        </Row>
                        <Button type="submit" className="bg-primary">
                            Submit
                        </Button>
                    </LocalForm>
                </ModalBody>
               </Modal>
            </div>
            );
        }
    }

    class PurchaseForm extends Component {

        constructor(props) {
            super(props);
            this.state = {
              isNavOpen: false,
              isModalOpen: false,
              account: null,
              stripePromise: null,
              client_secret: null,
              waiting: false,
            };
            this.toggleModal = this.toggleModal.bind(this);
            this.changeName = this.changeName.bind(this);
        }

        componentDidMount(){
            console.log('component mounted')
        }

        changeName = (event) => {
            this.setState({
                name: event.target.value
            });
            console.log('name: ', this.state.name)
        }

        toggleModal(){
            this.setState({
                isModalOpen: !this.state.isModalOpen
            });
        }

        createPayment(dishId) {
            if (this.props.slot1 || this.props.slot2 ){
                this.toggleModal();
                const bearer = 'Bearer ' + localStorage.getItem('token');
                console.log('creating payment...', dishId)
                const paymentDetails = {
                    id : dishId._id,
                    slot1: this.props.slot1,
                    slot2: this.props.slot2
                }
    
                fetch(baseUrl + 'checkout/', {
                    method: 'POST',
                    body: JSON.stringify(paymentDetails),
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                })
                .then( res => res.json())
                    .then(json => {
                        const {
                            success,
                            message,
                            client_secret,
                            account,
                        } = json;
                        console.log(json)
                        if (success) {
                            
                            console.log('client secret', client_secret)
                            console.log('account_id', account)
                            this.setState({
                                stripePromise : loadStripe(stripe_pk,
                                    {stripeAccount: account}),
                                    account: account,
                                    client_secret: client_secret,
                            });
                            
                        }
                        else {
                            console.log('failed', message)
                            
                        }
                    }
                )
                .catch(error => { console.log('Post comments ', error.message);
                    alert('Please log in first'); })
            }
            else {
                alert('You have not selected a time yet. Please specify the time for your booking.');
            }
        }
    
        render() {
            console.log('account state', this.state.account);
            console.log(this.props.dishId)
            return(
            <div>
                <LocalForm onSubmit={this.createPayment.bind(this,this.props.dishId)} >
                <Col md={{size: 12}}>
                    <div className="form-check">
                            <Control.checkbox model=".agree"
                                name="agree"
                                className="form-check-input"
                                validators={{
                                    checked
                                }}
                                />
                            <Errors 
                                className="text-danger"
                                model=".agree"
                                show="touched"
                                messages={{
                                    checked: 'Required',
                                    
                                }}
                            />
                            <p>Agree to Renter's<a href={imgUrl+ this.props.dishId.owner.terms}> Terms</a></p>
                    </div>
                    
                </Col>
                <Button type="submit" value="submit" color="primary">Pay Now</Button>
                </LocalForm>
                {
                    (this.state.account) ? (
                        <Modal isOpen= {this.state.isModalOpen} toggle ={this.toggleModal}>
                            <ModalBody>
                                <Row className="form-group">
                                <Col md= {2} className='ml-auto'>
                                <Button onClick={this.toggleModal}>X</Button>
                                </Col>
                                </Row>
                                <Row className="form-group">
                                <Col md={10}>
                                    <Elements stripe={this.state.stripePromise}>
                                        <SplitForm
                                        slot1 = {this.props.slot1}
                                        slot2 = {this.props.slot2}
                                        client_secret ={this.state.client_secret}
                                        booking_id = {this.props.dishId._id}
                                        date = {this.props.date}
                                        // date_time = {this.params} 
                                        />
                                        {/* <CardForm client_secret ={this.state.client_secret}></CardForm> */}
                                    </Elements>
                                </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    ) : (
                        <></>
                    )
                }
                
                
            </div>

            );
        }
    
    }

    class RenderCalendar extends Component {
        constructor(props) {
            super(props);
            
            this.state = {
                date: new Date(),
                slot1: false,
                slot2: false,
                slot1_avail: true,
                slot2_avail: true,
                today: new Date(),
                past: false,

            };
            this.dateChange = this.dateChange.bind(this);
            this.fetchAvailability = this.fetchAvailability.bind(this);
            this.slot1Change.bind(this); 
            this.slot2Change.bind(this); 
        }

        slot1Change(){
            this.setState({
                slot1: !this.state.slot1
            })
        }

        slot2Change(){
            this.setState({
                slot2: !this.state.slot2,
            })
        }

        componentWillMount() {
            var today = new Date;
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0); 
            today.setMilliseconds(0);
            console.log('today: ', today);
            this.setState({
                date: today,
                today: today,
            })
        }

        componentDidMount(){
            this.fetchAvailability(this.state.date)
        }
        
        dateChange = (date) => {

            if (date < this.state.today){
                this.setState({
                    past: true,
                })
            }
            else {
                this.setState({
                date: date,
                past: false,
                }, () => {
                    this.fetchAvailability(date); 
                })
            }
            
        }

        fetchAvailability = () => {
            console.log('bookingId: ',this.props.booking._id)
            const bearer = 'Bearer ' + localStorage.getItem('token');
            const booking = {
                bookingId : this.props.booking._id,
                date: this.state.date,
            }
            fetch(baseUrl + 'booking/available', {
                method: 'POST',
                headers: {
                    'Authorization': bearer,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(booking)
            })
            .then( res => res.json())
                .then(json => {
                    if (json.length === 0){
                        console.log('no bookings yet')
                        this.setState({
                            slot1_avail: true,
                            slot2_avail: true,
                        })
                    }
                    else{
                        console.log('existing bookings: ',json)
                        json.map((slot) => {
                            if(slot.slot1){
                                this.setState({
                                    slot1_avail: false
                                })
                            }
                            if (slot.slot2){
                                this.setState({
                                    slot2_avail: false
                                })
                            }
                        });
                    }
                })

        }
        
        render(){
            console.log('booking: ', this.props.booking)
            return(
                <>
                <Calendar onChange={(date) => this.dateChange(date)}
                value={this.state.date}/>
                <br></br>
                 <br></br>
                 {(this.state.past) ? (<p>Please select a valid date</p>): (
                    <>
                    <div className="row">
                 <Button onClick={this.slot1Change.bind(this)} disabled={!this.state.slot1_avail}>
                 {this.props.booking.slot1_start}:00AM to  {this.props.booking.slot1_end-12}:00PM</Button>
                 { (this.state.slot1) ? (<p>selected</p>) : (<></>)}
                 </div>
                 <br></br>
                 <div className="row">
                 <Button onClick={this.slot2Change.bind(this)} disabled={!this.state.slot2_avail}>
                 {this.props.booking.slot2_start-12}:00AM to  {this.props.booking.slot2_end-12}:00PM</Button>
                 { (this.state.slot2) ? (<p>selected</p>) : (<></>)}
                 </div>
                 <Card>
                     <CardTitle style={{ textAlign: 'center'}}>Booking Summary</CardTitle>
                     <CardBody>
                        <CardText>
                             {this.state.date.toDateString()}
                             {(this.state.slot1)? (<p>{this.props.booking.slot1_start}:00AM to  {this.props.booking.slot1_end-12}:00PM</p>) : (<></>)}
                             {(this.state.slot2)? (<p>{this.props.booking.slot2_start-12}:00PM to  {this.props.booking.slot2_end-12}:00PM</p>) : (<></>)}
                             {(this.state.slot1 && this.state.slot2)?
                                    (<p>rental: ${(this.props.booking.price/100).toFixed(2).toLocaleString('en-US',{
                                    style: 'currency', currency: 'USD'})} (full day) </p>)
                                :(
                                    <p>rental: ${(this.props.booking.halfprice/100).toFixed(2).toLocaleString('en-US',{
                                    style: 'currency', currency: 'USD'})} (half day)</p>
                                ) 
                            }
                        </CardText>
                        <PurchaseForm dishId={this.props.booking} date={this.state.date} slot1={this.state.slot1} slot2={this.state.slot2}/>  
                     </CardBody>
                 </Card>
                </>
                 )}
                </>
            )
        }
    }

    const DishDetail = (props) => {
        if (props.isLoading) {
            return(
                <div className="container">
                    <div className="row">
                        <Loading />
                    </div>
                </div>
            );
        }
        else if (props.errMess) {
            return(
                <div className="container">
                    <div className="row">
                        <h4>{props.errMess}</h4>
                    </div>
                </div>
            );
        }
        else if (props.dish != null)        
            return (
                <div className="container">
                    <div className="row">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to='/menu'>Menu</Link></BreadcrumbItem>
                            <BreadcrumbItem active>{props.dish.name}</BreadcrumbItem>
                        </Breadcrumb>
                        <div className="col-12">
                            <h3>{props.dish.name}</h3>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <RenderDish dish={props.dish} favorite={props.favorite} postFavorite={props.postFavorite} />
                        </div>
                        <div className="col-md-4 ">
                            <div className="calendar">
                                <RenderCalendar booking={props.dish}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <RenderComments comments={props.comments}
                                postComment={props.postComment}
                                dishId={props.dish._id} />
                        </div>
                    </div>
                </div>
            );
        else
            return(
                <div></div>
            );
    }

export default DishDetail;