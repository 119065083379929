import * as ActionTypes from './ActionTypes';

export const Leases = (state = {
        isLoading: true,
        errMess: null,
        leases: []

    }, action) => {
    switch(action.type) {
        
        case ActionTypes.ADD_LEASES:
            return {...state, isLoading:false, errMess: null, leases: action.payload}

        case ActionTypes.LEASES_LOADING:
            return {...state, isLoading:true, errMess: null, leases: []}

        case ActionTypes.LEASES_FAILED:
            return {...state, isLoading:false, errMess: action.payload, leases: []}

        case ActionTypes.ADD_COMMENT:
            var listing = action.payload;
            console.log("Listing: ", listing);
            return {...state, leases: state.leases.concat(listing)};
            
        default:
            return state;
    }
}