import React, {Component} from 'react'; 
import { Navbar, NavbarBrand, Jumbotron, Nav, NavbarToggler, Collapse, NavItem,
    Button, Modal, ModalHeader , ModalBody, Form, FormGroup, Row, Col, Input, Label} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import { Control, LocalForm, Errors, Redirect} from 'react-redux-form';
import S3FileUpload from 'react-s3';
import {bucketName,region,accessKeyId,secretAccessKey } from '../config';

const config = {
    bucketName: bucketName,
    dirName: 'terms', 
    region: region,
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
}

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => (val) && (val.length >= len);
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class Header extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false, 
            isModalOpen: false,
            isSignupOpen: false,
            renter: false,
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.toggleSignup = this.toggleSignup.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    toggleNav(){
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleModal(){
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    toggleSignup(){
        this.setState({
            isSignupOpen: !this.state.isSignupOpen
        });
        console.log('toggle Signup')
    }

    handleLogout() {
        this.props.logoutUser();
    }

    handleLogin(event){
        this.toggleModal();
        this.props.loginUser({username: this.username.value, password: this.password.value});
        event.preventDefault();
    }

    handleSignup(values){
        S3FileUpload
        .uploadFile(values.terms[0], config)
        .then(data => console.log('data: ', data))
        .catch(err => console.error(err))
        //handle terms name storage in backend

        this.toggleSignup();
        this.props.signUp( values);
        console.log(values.email + '  ' + values.renter)
    }

    handleChange(values){
        
    
        this.setState({
            renter: values.renter,
            
        });
    }


    render(){
        document.title = "ENI Environment | When Quality Matters";
        document.getElementsByTagName("META")[2].content="Best quality recycled products from the Caribbean";
        return(
            <>
            <Navbar dark expand="md">
                <div className="container" style={{margin: "1%"}}>
                    
                    <NavbarBrand className="mr-auto" href="/" style={{paddingRight:'3%'}}>
                        <img src="assets/images/eni-metals_logo_final.png" height="60" width="60" alt="ENI Metals Logo"/>
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNav} />
                    <Collapse isOpen={this.state.isNavOpen} navbar>
                        <Nav navbar style={{textAlign:"right"}}>
                            <NavItem>
                                <NavLink className="nav-link" to="/home">
                                    <span ></span> Home
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <HashLink className="nav-link" to="/home#company">
                                    <span ></span> About Us
                                </HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink className="nav-link" to="/projects">
                                    <span ></span> Projects
                                </HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink className="nav-link" to="/products">
                                    <span ></span> Products
                                </HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink className="nav-link" to="/home#work">
                                    <span ></span> Work with Us
                                </HashLink>
                            </NavItem>
                            {/* <NavItem>
                                <HashLink className="nav-link" to="/home#logistics">
                                    <span ></span> Logistics
                                </HashLink>
                            </NavItem> */}
                            
                            
                            {/* <NavItem>
                            { !this.props.auth.isAuthenticated ?
                            <Nav navbar>
                                <NavItem>
                            <NavLink className="nav-link" to="/aboutus">
                                <span ></span> About Us
                            </NavLink>
                                </NavItem>
                                <NavItem>
                            <NavLink className="nav-link" to="/contactus">
                                <span ></span> Contact Us
                            </NavLink>
                            </NavItem>
                            </Nav>
                            :
                            <NavLink className="nav-link" to="/myleases">
                            <span ></span> My Leases
                            </NavLink>
                            }
                            </NavItem> */}

                        </Nav>
{/* This is where the login/logout buttons live */}
{/*                         
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                            { !this.props.auth.isAuthenticated ?
                                    <div>
                                        <Button outline onClick={this.toggleModal}>
                                            Login
                                            {this.props.auth.isFetching ?
                                                <span className="fa fa-spinner fa-pulse fa-fw"></span>
                                                : null
                                            }
                                        </Button>
                                        <Button onClick={this.toggleSignup}>
                                            <span > Signup</span>
                                        </Button>
                                    </div>
                            :
                                <div>
                                <div className="navbar-text mr-3">{this.props.auth.user.username}</div>
                                    <Button outline onClick={this.handleLogout}>
                                        <span className="fa fa-sign-out fa-lg"></span> Logout
                                        {this.props.auth.isFetching ?
                                            <span className="fa fa-spinner fa-pulse fa-fw"></span>
                                            : null
                                        }
                                    </Button>
                                </div>
                                }
                            </NavItem>
                            
                        </Nav> */}
                        
                    </Collapse>
                </div>
            </Navbar>
            <Modal isOpen= {this.state.isModalOpen} toggle ={this.toggleModal}>
                <ModalHeader toggle ={this.toggleModal}>Login</ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.handleLogin}>
                            <FormGroup>
                                <Label htmlFor="username">Username</Label>
                                <Input type="text" id="username" name="username"
                                    innerRef={(input) => this.username = input} />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="password">Password</Label>
                                <Input type="password" id="password" name="password"
                                    innerRef={(input) => this.password = input}  />
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="remember"
                                    innerRef={(input) => this.remember = input}  />
                                    Remember me
                                </Label>
                            </FormGroup>
                            <Button type="submit" value="submit" color="primary">Login</Button>
                        </Form>
                    {/* {(this.props.auth.errMess)?(<p>{this.props.auth.errMess}</p>):(<></>)} */}
                </ModalBody>
            </Modal>
            <Modal isOpen= {this.state.isSignupOpen} toggle ={this.toggleSignup}>
                <ModalHeader toggle ={this.toggleSignup}>Signup</ModalHeader>
                <ModalBody>
                    <LocalForm onSubmit={(values) => this.handleSignup(values)}
                     onChange={(values) => this.handleChange(values)}>
                        <Row className="form-group">
                            <Label htmlFor="email" md={2}>Email</Label>
                            <Col>
                                <Control.text model=".email" name="email"
                                    placeholder="name@example.com"
                                    className="form-control"
                                    validators={{
                                        required,validEmail
                                    }}
                                />
                                <Errors 
                                    className="text-danger"
                                    model=".email"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        validEmail: 'Invalid Email Address'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group"> 
                            <Label htmlFor="username" md={2}>Username</Label>
                            <Col md={10}>
                                <Control.text model=".username" name="username"
                                    placeholder="Username"
                                    className="form-control"
                                    validators={{
                                        required,minLength: minLength(2), maxLength:maxLength(15)
                                    }}
                                />
                                <Errors 
                                    className="text-danger"
                                    model=".username"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="password" md={2}>Password</Label>
                            <Col md={10}>
                                <Control.password model=".password" name="password"
                                    placeholder=""
                                    className="form-control"
                                    validators={{
                                        required,minLength: minLength(2), maxLength:maxLength(15)
                                    }}
                                />
                                <Errors 
                                    className="text-danger"
                                    model=".password"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                        <Col md={2} xs={2}>
                        <Control.checkbox model=".renter" name="renter"
                            className="form-control-sm" />
                            </Col>
                        <Col md={8} xs={8}> 
                            <Label htmlFor="renter" className="form-control-sm" > Will you be renting out your equiptment?</Label>    
                        </Col>
                        </Row>
                        { (this.state.renter) ?
                        (<>
                        <Row className="form-group">
                        <Col>
                        <Label htmlFor="terms">Terms &amp; Conditions</Label>
                        <br></br>
                        <Control.file
                            accept='.pdf'
                            model=".terms"
                            validators={{
                                required,
                              }}
                            />
                            <Errors 
                                className="text-danger"
                                model=".terms"
                                show="touched"
                                messages={{
                                    required: 'Required',
                                }}
                            />
                        </Col>
                    </Row>
                        <Row className="form-group">
                            <Col>
                                <Label htmlFor="description">Description</Label>
                                <p>
                                <small>Example: 'Alex has been renting boats for 5 happy years now. We are located at 121 Somewhere Warm Blvd, USA. For any questions please call 248-877-4154. A $200 damage/equipment/loss deposit is required per vessel, due on the day of the rental. Customer is responsible for ALL damages to vessel.'</small>
                                </p>
                                <Control.textarea model=".description" id="description" className="form-control"
                                rows="5"
                                placeholder="Your description goes here..."
                                defaultValue="" 
                                validators={{
                                    required,
                                }}
                                />
                                <Errors 
                                    className="text-danger"
                                    model=".description"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <Label htmlFor="cancellation">Cancellation Policy</Label>
                                <p><small>
                                Example: 'If you cancel prior to your 48 hour notice, your deposit will be refunded.  If you cancel or change past the 48 hour notice, you will not be refunded the $100 deposit.'    
                                    </small></p>
                                <Control.textarea model=".cancellation" id="cancellation" className="form-control"
                                rows="5"
                                placeholder="Your cancellation policy goes here..."
                                defaultValue="" 
                                validators={{
                                    required,
                                }}
                                />
                                <Errors 
                                    className="text-danger"
                                    model=".cancellation"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <Label htmlFor="weather">Weather Policy</Label>
                                <p>
                                    <small>
                                    Example: 'If there are heavy rains, thunderstorms or high winds, we reserve the right to reschedule your reservation. However, in the case of cooler / hot weather, cloudy etc., the renter is still responsible, as all boats will be released to go out on the water.'        
                                    </small>
                                </p>
                                <Control.textarea model=".weather" id="weather" className="form-control"
                                rows="5"
                                placeholder="Your weather policy goes here..."
                                defaultValue="" 
                                validators={{
                                    required,
                                }}
                                />
                                <Errors 
                                    className="text-danger"
                                    model=".weather"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                    }}
                                />
                            </Col>
                        </Row>
                        </>)
                            :
                        (<></>)
                    }
                        <Col md={{size: 10, offset: 2}}> 
                            <Button type="submit" value="submit" color="primary">Create Account</Button>
                        </Col>
                    </LocalForm>
                </ModalBody>
            </Modal>
            </>
        );
    }
}

export default Header; 