import React, { useMemo, useState } from "react";
import {Redirect} from 'react-router-dom'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js"; 
import {baseUrl} from '../shared/baseUrl';

import useResponsiveFontSize from "../useResponsiveFontSize";


const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = (props) => {
  const secret = props.client_secret;
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [name, setName] = useState(
    
  );

  const [zip, setZip] = useState(
    null
    );

  const [success, setSuccess] = useState(
    null
  );

  const [btnState, setBtn] = useState(
    false
  );

  const handleBtn = (value) => setBtn(value);

  const handleChange = event => setName(event.target.value);

  const handleZip = event => setZip(event.target.value);

  const handleSuccess = (value) => setSuccess(value);

  const required = (val) => val && val.length;

    const postBilling = () => {
    
      const bookingId = props.booking_id;
      const date = props.date; 
      console.log('booking at '+ baseUrl + 'booking/' + ' with ' +  bookingId + 'on ' + date)
      const newBooking = {
        bookingId : bookingId,
        date: date,
        slot1: props.slot1,
        slot2: props.slot2,
      }
      const bearer = 'Bearer ' + localStorage.getItem('token');
      fetch (baseUrl + 'booking/',{
          method: 'POST',
          body: JSON.stringify(newBooking),
          headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json'
          },
          credentials: 'same-origin',
      }
      )
      .then(res => res.json())
        .then(json => {
            const {
                // success,
                // message,
                // setupBegan,
                // account,
            } = json;
        }
      );
    }

  const handleSubmit = async (event) => {
    // console.log(this.props.name)
    event.preventDefault();
    handleSuccess('Wait just a moment');
    handleBtn(true); 
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }


    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: name,
          address:{
            postal_code :zip,
          }
        },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result);
      handleBtn(false); 
      if (result.error.code === "parameter_invalid_empty"){
        handleSuccess('Please fill out all fields');
      }
      else {
      handleSuccess(result.error.message);
      }
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        handleSuccess('Payment Successful!');
        postBilling()
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
    console.log(result)
    console.log("[PaymentMethod]", payload);
  };
      return (
        <>
        <form onSubmit={handleSubmit}>
        <label>
          Name on card
           <input type="text" className="StripeElement"
          placeholder="John Appleseed" onChange={handleChange} /> 
        </label>
          <label>
            Card number
            <CardNumberElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </label>
          <label>
            Expiration date
            <CardExpiryElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </label>
          <label>
            CVC
            <CardCvcElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </label>
          <label>
          Zip Code
          
           <input type="text" className="StripeElement"
          placeholder="12345" onChange={handleZip} /> 
          
        </label>
          <br></br>
          <button type="submit" disabled={btnState}>
            Pay
          </button>
          <p>{success}</p>
        </form>
        </>
      )
};



export default SplitForm;
