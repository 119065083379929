import React, { useState } from 'react';
import { Card, CardImg, CardImgOverlay, CardText, CardTitle, UncontrolledCarousel } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';
import { imgUrl } from '../shared/baseUrl';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: '/assets/images/excavator_sky.jpg',
    altText: 'Slide 1',
    caption: 'Scrapyard'
  },
  {
    src: '/assets/images/aluminum_sheets.jpg',
    altText: 'Slide 2',
    caption: 'Stainless Steel'
  },
  {
    src: '/assets/images/Aluminium rims.jpg',
    altText: 'Slide 3',
    caption: 'Slide 3'
  }
];

const items2 = [
  {
    src: '/assets/images/excavator_scrap.jpg',
    altText: 'Non-Ferrous',
    caption: 'Non-Ferrous'
  },
  {
    src: '/assets/images/Bare bright (1).jpg',
    altText: 'Incinerated Scrap',
    caption: 'Incinerated Scrap'
  },
  {
    src: '/assets/images/Bare bright (2).jpg',
    altText: 'Tire Wire',
    caption: 'Tire Wire'
  }
];

const items3 = [
  {
    src: '/assets/images/excavator_fuel.jpg',
    altText: 'Non-Ferrous',
    caption: 'Non-Ferrous'
  },
  {
    src: '/assets/images/Electronic engines with copper windings.jpg',
    altText: 'Incinerated Scrap',
    caption: 'Incinerated Scrap'
  },
  {
    src: '/assets/images/Alu.Fe engines.jpg',
    altText: 'Tire Wire',
    caption: 'Tire Wire'
  }
];





const Project = (props) => {
  

  return (
    <div className="container" >
      <div className="row align-items-start " id="finance" >
            <div className="col-12 col-md m-1" >
            <Card className="projects">
            <CardTitle tag="h5">Projects</CardTitle>
            <CardText>
              {<p>
                One of our main sources of material is through demolition projects. We are equipped and experienced to take on small scale and large projects, including specialized cold cutting projects. We can provide the full circle starting with demolition, removal, processing and shipping of the scrap metals. We always ensure the project is completed on time or before schedule and with safety first, for our own team as well as the project owners.     
                </p>}
            

            </CardText>
            <CardImg src={"assets/images/yard_dry.jpg"} style={{padding:'1em 0em 1em 0em'}}/>
            <CardImg src={"assets/images/excavator_scrap.jpg"} style={{padding:'1em 0em 1em 0em'}}/>
            <CardImg src={"assets/images/excavator_sky.png"} style={{padding:'1em 0em 1em 0em'}}/>
            <CardImg src={"assets/images/excavator_fuel.jpg"} style={{padding:'1em 0em 0em 0em'}}/>
            </Card>
            </div>
        </div>
    </div>
  );
}

export default Project;